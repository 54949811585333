import React from 'react';

export const StyledDivider = () => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 790.97 47.74">
            <rect x="0.5" y="20.64" width="283.87" height="6.45" stroke="#000" strokeMiterlimit="10"/>
            <rect x="506.6" y="20.64" width="283.87" height="6.45" stroke="#000" strokeMiterlimit="10"/>
            <path
                d="M416.41,510.48s40.92-36.54,77.46,0,84.77-16.24,84.77-16.24-23.39-39.62-87.69,0S416.41,510.48,416.41,510.48Z"
                transform="translate(-102.04 -476.13)" stroke="#000" strokeMiterlimit="10"/>
        </svg>
    );
};


export const ErrorImage = () => {
    return (
        <svg id="bb43b303-7783-4b20-84ac-89898836e9dc" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
             width="794.1218" height="505.34514" viewBox="0 0 794.1218 505.34514">
            <path
                d="M247.4579,701.19842c-12.25095-1.00787-23.067-9.87117-29.218-20.51384-2.06275-3.569-3.47609-8.25063-1.14018-11.64717a6.40928,6.40928,0,0,1,11.315,1.47119,62.04539,62.04539,0,0,1-.96485-12.64086c.09416-3.586.77176-7.64052,3.75358-9.6347,3.45928-2.31349,8.44549-.47663,10.96167,2.83815s3.19894,7.62671,3.80283,11.74426a10.83041,10.83041,0,0,1,12.03865-13.65713c5.06672.76515,9.03581,5.1519,10.58457,10.03641s1.06522,10.18071.06842,15.207c-1.16883,5.89373-3.06582,11.74062-6.481,16.68417s-8.488,8.929-14.37353,10.13822Z"
                transform="translate(-202.9391 -197.32743)" fill="#f2f2f2"/>
            <path
                d="M997.0609,303.32743a106.03391,106.03391,0,0,1-71,100.08c-.66016.23-1.33008.46-2,.67v-107.71h-138.77c.04-.67.08984-1.34.1499-2H924.0609v-21.04a5.002,5.002,0,0,0-5-5H790.98082c.23-.67.47021-1.34.73-2a106.01022,106.01022,0,0,1,205.3501,37Z"
                transform="translate(-202.9391 -197.32743)" fill="#f2f2f2"/>
            <path
                d="M919.0609,266.32743h-487a7.00778,7.00778,0,0,0-7,7v330a7.00778,7.00778,0,0,0,7,7h487a7.00779,7.00779,0,0,0,7-7v-330A7.00778,7.00778,0,0,0,919.0609,266.32743Zm5,337a5.002,5.002,0,0,1-5,5h-487a5.002,5.002,0,0,1-5-5v-330a5.002,5.002,0,0,1,5-5h487a5.002,5.002,0,0,1,5,5Z"
                transform="translate(-202.9391 -197.32743)" fill="#3f3d56"/>
            <rect x="223.1218" y="97.03998" width="499" height="2" fill="#3f3d56"/>
            <circle cx="240.1218" cy="84" r="6" fill="#3f3d56"/>
            <circle cx="257.3718" cy="84" r="6" fill="#3f3d56"/>
            <circle cx="274.6218" cy="84" r="6" fill="#3f3d56"/>
            <path
                d="M562.0609,335.32743h-88a7.00778,7.00778,0,0,0-7,7v88a7.00778,7.00778,0,0,0,7,7h88a7.00779,7.00779,0,0,0,7-7v-88A7.00778,7.00778,0,0,0,562.0609,335.32743Zm5,95a5.002,5.002,0,0,1-5,5h-88a5.002,5.002,0,0,1-5-5v-88a5.002,5.002,0,0,1,5-5h88a5.002,5.002,0,0,1,5,5Z"
                transform="translate(-202.9391 -197.32743)" fill="#3f3d56"/>
            <path
                d="M720.0609,335.82743h-88a6.51259,6.51259,0,0,0-6.5,6.5v88a6.51259,6.51259,0,0,0,6.5,6.5h88a6.51259,6.51259,0,0,0,6.5-6.5v-88A6.51259,6.51259,0,0,0,720.0609,335.82743Z"
                transform="translate(-202.9391 -197.32743)" fill="#793661"/>
            <path
                d="M878.0609,335.82743h-88a6.51259,6.51259,0,0,0-6.5,6.5v88a6.51259,6.51259,0,0,0,6.5,6.5h88a6.51259,6.51259,0,0,0,6.5-6.5v-88A6.51259,6.51259,0,0,0,878.0609,335.82743Z"
                transform="translate(-202.9391 -197.32743)" fill="#e6e6e6"/>
            <path
                d="M562.0609,467.82743h-88a6.51259,6.51259,0,0,0-6.5,6.5v88a6.51259,6.51259,0,0,0,6.5,6.5h88a6.51259,6.51259,0,0,0,6.5-6.5v-88A6.51259,6.51259,0,0,0,562.0609,467.82743Z"
                transform="translate(-202.9391 -197.32743)" fill="#e6e6e6"/>
            <path
                d="M720.0609,467.82743h-88a6.51259,6.51259,0,0,0-6.5,6.5v88a6.51259,6.51259,0,0,0,6.5,6.5h88a6.51259,6.51259,0,0,0,6.5-6.5v-88A6.51259,6.51259,0,0,0,720.0609,467.82743Z"
                transform="translate(-202.9391 -197.32743)" fill="#793661"/>
            <path
                d="M878.0609,467.82743h-88a6.51259,6.51259,0,0,0-6.5,6.5v88a6.51259,6.51259,0,0,0,6.5,6.5h88a6.51259,6.51259,0,0,0,6.5-6.5v-88A6.51259,6.51259,0,0,0,878.0609,467.82743Z"
                transform="translate(-202.9391 -197.32743)" fill="#e6e6e6"/>
            <path
                d="M540.5609,482.32743h-88a6.50745,6.50745,0,0,1-6.5-6.5v-88a6.50744,6.50744,0,0,1,6.5-6.5h88a6.50745,6.50745,0,0,1,6.5,6.5v88A6.50745,6.50745,0,0,1,540.5609,482.32743Z"
                transform="translate(-202.9391 -197.32743)" fill="#793661"/>
            <polygon points="202.746 492.088 214.466 488.491 206.17 441.573 188.872 446.881 202.746 492.088"
                     fill="#a0616a"/>
            <path
                d="M403.482,680.85789h38.53073a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H418.36887A14.88686,14.88686,0,0,1,403.482,680.8579v0A0,0,0,0,1,403.482,680.85789Z"
                transform="translate(825.89112 1024.95669) rotate(162.93912)" fill="#2f2e41"/>
            <polygon points="102.748 492.358 115.008 492.357 120.84 445.069 102.746 445.07 102.748 492.358"
                     fill="#a0616a"/>
            <path
                d="M303.06,686.18167h38.53073a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H317.94689A14.88686,14.88686,0,0,1,303.06,686.18168v0a0,0,0,0,1,0,0Z"
                transform="translate(441.74335 1189.90807) rotate(179.99738)" fill="#2f2e41"/>
            <path
                d="M304.80743,552.60788a9.16224,9.16224,0,0,1,.318-14.04558l9.50536-119.69459,19.33086,4.8297L318.48407,540.45594a9.21188,9.21188,0,0,1-13.67664,12.15194Z"
                transform="translate(-202.9391 -197.32743)" fill="#a0616a"/>
            <circle cx="140.57921" cy="150.55523" r="24.56103" fill="#a0616a"/>
            <path
                d="M322.16166,415.18605c-5.54194-7.60815-5.71269-18.08683.25708-25.36412,3.89587-4.74914,10.41248-8.54951,21.29248-8.54951,29,0,40,23,40,23s12,22,5,42-7,22-7,22l-46-4S343.47142,444.44079,322.16166,415.18605Z"
                transform="translate(-202.9391 -197.32743)" fill="#ccc"/>
            <path
                d="M307.9766,435.09469l9.90967-32.4209a15.50189,15.50189,0,0,1,21.93018-9.24512h0a15.53,15.53,0,0,1,7.08789,20.00977L334.306,442.12984Z"
                transform="translate(-202.9391 -197.32743)" fill="#ccc"/>
            <path d="M380.71122,463.27242l39,202-21,6-51-133-23,136-22,3s-9.65032-179.945,33-213Z"
                  transform="translate(-202.9391 -197.32743)" fill="#2f2e41"/>
            <path
                d="M436.84246,453.80485a10.52712,10.52712,0,0,0-.96265,1.3493l-49.53505,2.40166-9.53834-29.11951-16.56185,7.87143,15.58776,43.3713,61.26206-11.98076a10.49579,10.49579,0,1,0-.25193-13.89342Z"
                transform="translate(-202.9391 -197.32743)" fill="#a0616a"/>
            <path
                d="M353.31425,407.17672a15.50085,15.50085,0,0,1,16.3623-17.28223h0a15.52954,15.52954,0,0,1,14.53467,15.47168v31.335l-26.936,4.14453Z"
                transform="translate(-202.9391 -197.32743)" fill="#ccc"/>
            <path
                d="M348.91975,372.66645c-3.68637-.01605-6.53017-3.89721-6.36494-7.57991s2.63867-6.92285,5.61866-9.09293,6.47479-3.49191,9.798-5.08739,6.61743-3.58561,8.66924-6.64822a14.71557,14.71557,0,0,0,1.02332-13.50438,21.66865,21.66865,0,0,0-9.3332-10.22418,28.37777,28.37777,0,0,0-37.60707,8.25038l-4.20808,11.45787c-4.28786,5.15613-4.968,12.81333-2.33217,18.97968s8.24814,10.80579,14.58112,13.0114a35.79392,35.79392,0,0,0,19.8411.59488"
                transform="translate(-202.9391 -197.32743)" fill="#2f2e41"/>
            <path
                d="M326.47317,334.50613c-1.80894-2.89931-4.94439-4.74325-8.18587-5.82543a25.72431,25.72431,0,0,0-33.83244,23.00283c-.34357,6.31437,1.63365,12.48912,2.79545,18.70518s1.39291,13.08161-2.03446,18.396c-2.61053,4.0478-6.96387,6.59634-11.26083,8.77251-3.58128,1.81371-7.45507,3.51578-11.45314,3.1544s-8.01642-3.52812-7.91033-7.54108a32.0544,32.0544,0,0,0-2.68163,9.08033c-.28127,3.15593.49659,6.56125,2.77606,8.76193,3.3131,3.19859,8.56255,2.9646,13.1157,2.27441,9.85694-1.49417,19.83818-4.29543,27.62552-10.52031s12.95465-16.47494,10.90388-26.23127c-.85666-4.07547-2.86993-7.796-4.49677-11.62968s-2.89692-8.027-2.14959-12.124a14.73513,14.73513,0,0,1,7.49255-9.98176,19.60142,19.60142,0,0,1,12.52266-1.899c2.79906.45932,6.29406,1.17251,7.95419-1.12742a4.50752,4.50752,0,0,0-.09367-4.80858,13.24754,13.24754,0,0,0-3.59259-3.5596"
                transform="translate(-202.9391 -197.32743)" fill="#2f2e41"/>
            <path
                d="M511.4238,702.67257h-307.294a1.19069,1.19069,0,1,1,0-2.38137h307.294a1.19069,1.19069,0,1,1,0,2.38137Z"
                transform="translate(-202.9391 -197.32743)" fill="#3f3d56"/>
        </svg>
    )
}
