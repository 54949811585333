export const palette = {
  black: '#000000',
  white: '#FFFFFF',
  byzantium: '#793661',
  CadmiumOrange: '#EF9443',
  grayish: '#f8f9fa'
}

export const typeScale = {
  header1: '4.209rem',
  header2: '3.157rem',
  header3: '2.369rem',
  header4: '1.777rem',
  header5: '1.333rem',
  paragraph: '1rem',
  subtext: '0.75rem',
}