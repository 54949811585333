import React from "react"
import {ButtonWrapper, SecondaryButtonWrapper} from "./button.style"

const Button = ({children, link, type}) => {
  return type === 'primary' ?
    <ButtonWrapper to={link}>
      {children}
    </ButtonWrapper>
   : <SecondaryButtonWrapper to={link}>
        {children}
      </SecondaryButtonWrapper>
}


export default Button
