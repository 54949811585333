import styled from "styled-components"
import { palette, typeScale } from "../../utils/elements"
import { Link } from "gatsby"

export const SectionWrapper = styled.section`
  padding: 100px 0 50px;
`;

export const AboutWrapper = styled.section``;

export const FirstAboutWrapper = styled.div`
  position: relative;
  padding: 100px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .9);
`;

export const FirstAbout = styled.div`
  @media only screen and (min-width: 600px){
    p, h3{
    width: 60%;
    }
  }
`;

export const Title = styled.div`
  padding: 20px;
  border: 10px solid black;
  color: black;
  font-size: ${typeScale.header4};
  font-weight: bold;
  letter-spacing: .7rem;
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  width: fit-content;
`;

export const FirstAboutTitle = styled.h3`
  font-size: ${typeScale.header3};
  color: white;
  letter-spacing: 0.9rem;
  text-transform: uppercase;
`;

export const SecondParagraph = styled.p`
  font-size: ${typeScale.paragraph};
  line-height: 250%;
  margin-top: 50px;
  color: white;
`;

export const Paragraph = styled(SecondParagraph)`
  color: black;
`;

export const More = styled(Link)`
  font-size: ${typeScale.paragraph};
  color: ${palette.byzantium};
  font-weight: bolder;
  text-decoration: none;
`;

export const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const SecondAbout = styled.div`
  display: flex;
  .gatsby-image-wrapper{
  display: none;
  position: relative;
  }
  h1{
  font-size: 1.4rem;
  }
  svg{
  width: 200px;
  display: block;
  margin: 20px auto;
  }
  @media only screen and (min-width: 1200px){
  .gatsby-image-wrapper{
    display: inline-block;
  }
  h1{
  font-size: ${typeScale.header1};
  }
  .gatsby-image-wrapper::before{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255) 100%);
  }
   >*{
  width: 50%;
  }
  >div:last-of-type{
  transform: translateX(-100px);
  z-index: 2;
  margin: auto 0;
  }
  }
`;

export const HeaderOne = styled.h1`
  font-size: ${typeScale.header2};
  letter-spacing: 1.1rem;
  margin-bottom: 30px !important;
  text-transform: uppercase;
`;

export const HeaderTwo = styled.h2`
  font-size: ${typeScale.header2};
  letter-spacing: 1.1rem;
  margin-bottom: 30px !important;
  text-transform: uppercase;
`;