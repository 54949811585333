import { createGlobalStyle } from "styled-components"

export const Global = createGlobalStyle`
  body{
  font-size: 100%;
  font-family: 'Montserrat',serif !important;
  margin: 0;
  }
  *{
  padding: 0;
  margin: 0;
  }
  h1, h2, h3, h4, h5, h6{
  font-weight: bold;
  }
`